@use 'styles/functions';

.button {
  --padding: 0;
  --height: #{functions.rem(24)};
}

.sm {
  --height: #{functions.rem(32)};
}

.md {
  --height: #{functions.rem(36)};
}

.lg {
  --height: #{functions.rem(40)};
}

.xl {
  --height: #{functions.rem(48)};
}

.icon {
  height: var(--icon-size);
  width: var(--icon-size);
}
