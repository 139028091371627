@use 'styles/functions';
@use 'styles/colors';

.root {
  position: relative;
  display: flex;
  gap: functions.rem(10);
  align-items: center;
}

.secondaryBalance {
  background-color: colors.$grey-85;
  height: 100%;
  padding: functions.rem(10) functions.rem(20);
  border-radius: functions.rem(100);

  span {
    font-weight: 800;
  }
}

.secondaryWallet {
  left: 0;
  margin-top: functions.rem(5);
  position: absolute;
  right: 0;
  top: 100%;
}

.balanceSpinner {
  --loader-size: #{functions.rem(23)};
}
