@use 'styles/colors';
@use 'styles/functions';
@import 'styles/typography';

.root {
  display: block;
  position: relative;
}

.container {
  align-items: center;
  display: flex;
  min-height: functions.rem(18);
  overflow: hidden;
  position: relative;

  &:hover .expandIcon {
    color: colors.$blue-60;
  }

  &.expanded {
    background: colors.$grey-80;
    border-radius: functions.rem(8);
    box-shadow: functions.rem(5) functions.rem(5) functions.rem(20)
      rgba(colors.$black, 0.2),
      functions.rem(-5) functions.rem(-5) functions.rem(20)
      rgba(colors.$black, 0.2);
    flex-wrap: wrap;
    left: functions.rem(-12);
    overflow: visible;
    padding: functions.rem(9) functions.rem(12);
    position: absolute;
    transition: background-color 0.3s;
    z-index: 3;
  }
}

.sm {
  &.root,
  .container {
    height: functions.rem(18);
    width: functions.rem(225);
  }

  .elementName {
    @extend %caption-3;
    letter-spacing: functions.rem(0.25);
  }

  .expanded {
    height: auto;
    padding-right: functions.rem(20);
    top: functions.rem(-9);
    width: functions.rem(249);
  }
}

.md {
  &.root,
  .container {
    height: functions.rem(32);
    width: functions.rem(299);
  }

  .elementName {
    @extend %caption-2;
  }

  .expanded {
    height: auto;
    top: functions.rem(-2);
    width: functions.rem(320);
  }
}

.wrapper {
  align-items: center;
  display: flex;
}

.elementName {
  text-decoration: none;
  white-space: nowrap;
}

.navElement {
  cursor: pointer;
}

.chevron {
  color: colors.$grey-75;
  height: functions.rem(18);
  width: functions.rem(18);
}

.expandWrapper {
  height: 100%;
  position: relative;
  width: 100%;
}

.gradient {
  height: 100%;
  pointer-events: none;
  position: absolute;
  width: 100%;
}

.expandIcon {
  color: colors.$grey-65;
  cursor: pointer;
  margin-left: auto;
  position: absolute;
  right: 0;
  transition: all 0.3s;

  &.upward {
    align-self: flex-start;
    color: colors.$white;
    margin-right: functions.rem(11);
    transform: rotate(-180deg);
  }
}
