@use 'styles/colors';
@use 'styles/functions';

.root {
  margin-bottom: functions.rem(18);
}

.menuSection {
  color: colors.$white;
  list-style: none;
  margin: 0;
  padding: 0;
}
