/* stylelint-disable */
@use 'styles/colors';
@use 'styles/functions';
@import 'styles/typography';

$popup-color: colors.$grey-85;

.root {
  display: block;
}

.label {
  align-items: center;
  display: flex;
}

.icon {
  color: colors.$grey-65;
  margin-right: functions.rem(6);
}

.date {
  outline: none;

  &:hover {
    color: colors.$grey-65;
  }
}

.popup {
  align-items: center;
  background-color: $popup-color;
  border-radius: functions.rem(8);

  display: flex;
  flex-direction: column;
  padding: functions.rem(32) functions.rem(36);

  :global(.react-datepicker) {
    background-color: $popup-color;
    border: 0;
  }

  :global(.react-datepicker__header) {
    background-color: transparent;
    border: 0;
  }

  :global(.react-datepicker__current-month) {
    @extend %body-1;
    color: colors.$white;
    margin-bottom: functions.rem(16);
  }

  :global(.react-datepicker__day-name) {
    @extend %caption-2;
    color: colors.$grey-5;
    margin-bottom: 0;
    margin-top: 0;
  }

  :global(.react-datepicker__day--today) {
    background-color: transparent;
  }

  :global(.react-datepicker__day) {
    @extend %body-2;
    color: colors.$white;

    height: functions.rem(32);
    line-height: functions.rem(32);
    margin: 0;
    outline: none;
    width: functions.rem(32);

    &:hover {
      background-color: colors.$grey-80;
      border-radius: functions.rem(32);
    }
  }

  :global(.react-datepicker__day--outside-month) {
    color: colors.$grey-75;
  }

  :global(.react-datepicker__day--selected) {
    background-color: colors.$grey-75;
    border-radius: functions.rem(32);

    &:hover {
      background-color: colors.$grey-75;
    }
  }

  :global(.react-datepicker__navigation) {
    background-position: center center;

    background-repeat: no-repeat;
    border: 0;
    height: functions.rem(20);
    outline: none;
    width: functions.rem(20);
  }

  :global(.react-datepicker__navigation--previous) {
    background-image: url('data:image/svg+xml,%3Csvg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M5 1L1 5L5 9" stroke="%23898989" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/%3E%3C/svg%3E%0A');
    left: functions.rem(50);
  }

  :global(.react-datepicker__navigation--next) {
    background-image: url('data:image/svg+xml,%3Csvg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M1 9L5 5L1 1" stroke="%23898989" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/%3E%3C/svg%3E%0A');
    right: functions.rem(50);
  }

  .buttons {
    display: flex;
  }

  .button {
    width: functions.rem(130);

    &:first-of-type {
      margin-right: functions.rem(8);
    }
  }

  .applyButton::after {
    background-color: $popup-color;
  }
}
