@use 'styles/functions';
@use 'styles/colors';

.root {
  --loader-size: #{functions.rem(80)};
  --check-height: calc(var(--loader-size) / 2);
  --check-width: calc(var(--check-height) / 2);
  --check-left: calc(var(--loader-size) / 6 + var(--loader-size) / 12);
  --check-thickness: #{functions.rem(3)};
  --check-color: #{colors.$white};

  display: block;
  height: var(--loader-size);
  position: relative;
  width: var(--loader-size);
}

.spinner {
  animation: loader-spin 1.2s infinite linear;
  height: 100%;
  width: 100%;

  &.complete {
    animation-play-state: paused;
    transform: rotate(45deg);
    transition: transform 0.8s;
  }
}

.checkMark {
  display: none;

  &.draw {
    display: block;
  }

  &.draw::after {
    animation-duration: 800ms;
    animation-name: checkMark;
    animation-timing-function: ease;
    transform: scaleX(-1) rotate(135deg);
  }

  &::after {
    border-right: var(--check-thickness) solid var(--check-color);
    border-top: var(--check-thickness) solid var(--check-color);
    content: '';
    height: var(--check-height);
    left: var(--check-left);
    opacity: 1;
    position: absolute;
    top: var(--check-height);
    transform-origin: left top;
    width: var(--check-width);
  }
}

@keyframes loader-spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes checkMark {
  0% {
    height: 0;
    opacity: 1;
    width: 0;
  }

  20% {
    height: 0;
    opacity: 1;
    width: var(--check-width);
  }

  40% {
    height: var(--check-height);
    opacity: 1;
    width: var(--check-width);
  }

  100% {
    height: var(--check-height);
    opacity: 1;
    width: var(--check-width);
  }
}

.circle {
  height: var(--loader-size);
  position: absolute;
  width: var(--loader-size);

  &.left {
    clip: rect(0, calc(var(--loader-size) / 2), var(--loader-size), 0);
    z-index: 1;
  }

  &.right {
    clip: rect(0,
    var(--loader-size),
    var(--loader-size),
    calc(var(--loader-size) / 2));
    transform: rotate(-120deg);
    transition: transform 0.8s;
    z-index: 0;
  }
}

.right {
  &.complete {
    transform: rotate(0);
  }
}
