@use 'styles/functions';

.root {
  --tabs-padding: #{functions.rem(32)};

  display: block;
  height: 100%;
  position: relative;
}

.tabs {
  height: 100%;
  padding-top: var(--tabs-padding);
}

.headerButtons {
  display: flex;
  gap: functions.rem(10);
  position: absolute;
  right: var(--tabs-padding);
  top: var(--tabs-padding);
}
