@use 'styles/colors';
@use 'styles/functions';
@import 'styles/typography';

.root {
  background-image: url('../../images/dotted-background.svg');
  height: calc(100vh - var(--header-height));
}

.title {
  margin-bottom: functions.rem(22);
}

.deposits {
  padding-top: functions.rem(26);
}

.migrationNotice {
  margin-top: 0;
}
