@use 'styles/colors';
@use 'styles/functions';
@import 'styles/typography';

.swapPage {
  background-image: url('../../images/dotted-background.svg');
  display: block;
  height: calc(100vh - var(--header-height));
  overflow-y: auto;
  padding: functions.rem(40);
}

.header {
  @extend %heading-5;
  margin-bottom: functions.rem(18);
  margin-left: 0;
}

.description {
  @extend %paragraph-3;
  margin-bottom: functions.rem(32);
  margin-top: 0;
  width: functions.rem(700);
}

.steps {
  display: block;
  clear: both;
  width: fit-content;
  overflow: hidden;
}

.accordian {
  margin-bottom: functions.rem(12);
  width: functions.rem(700);
}

.panel {
  background-color: colors.$grey-85;
  padding: functions.rem(20);
  border-radius: functions.rem(8);
}

.swapButton {
  text-decoration: none;
}

.swapBox {
  background-color: colors.$grey-85;
  padding: functions.rem(20);
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: functions.rem(100);
  border-radius: functions.rem(8);
  overflow: hidden;
  margin: functions.rem(12) 0 functions.rem(16) 0;
}

.swapDetails {
  display: flex;
  flex-direction: column;

  .label {
    color: colors.$grey-65;
    margin-bottom: functions.rem(8);
  }

  .value {
    font-size: functions.rem(24);
    font-weight: 800;
  }
}

.migrationNotice {
  margin-top: 1rem;
  border-color: #36ff7a;

  h2 {
    margin-top: 0;
    color: #36ff7a;
    font: 1.4rem;
  }

  strong {
    color: #e85ef6;
  }
}
