@use 'styles/colors';
@use 'styles/functions';

.root {
  display: flex;
  flex-direction: column;
}

.tabs {
  border-bottom: functions.rem(2) solid colors.$grey-80;
  display: flex;
  padding: 0 functions.rem(28);
}

.content {
  flex: 1;
  height: calc(100% - #{functions.rem(44)});
  padding: 0 functions.rem(40);
}
