@use 'styles/colors';
@use 'styles/functions';
@import 'styles/typography';

.balanceChange {
  @extend %caption-2;
}

.assetCell {
  color: colors.$white;
}

.narrowColumns {
  max-width: functions.rem(150) !important;
}

.balanceInfo {
  color: colors.$white;
  text-align: right;
}

.balance {
  @extend %title-3;
}

.rate {
  @extend %title-7;
}

.positive {
  color: colors.$success-40;
}

.negative {
  color: colors.$error-40;
}

.noDepositImg {
  max-width: 20%;
}

.actions {
  align-items: center;
  display: flex;
  gap: functions.rem(10);
}

.withdrawModal {
  padding: 0;
  text-align: left;
  overflow: hidden;

  .withdrawModalContent {
    padding: functions.rem(30);

    h1 {
      font-size: functions.rem(18);
    }

    .withdrawDetails {
      display: flex;
      justify-content: space-between;
      margin-bottom: functions.rem(15);
    }

    .maxButton {
      border: 0;
    }

    .withdrawButtons {
      margin-top: functions.rem(15);
      display: flex;
      justify-content: space-between;
      gap: functions.rem(15);

      button {
        width: 100%;
      }
    }
  }
}

.table {
  color: colors.$white;
  overflow: hidden;

  .apr {
    color: colors.$success-50;
  }

  .actionButtons {
    display: flex;
    gap: functions.rem(10);
  }
}

.activeDeposit {
  width: 100%;
  border-collapse: collapse;

  thead {
    th {
      font-size: 0.9rem;
      padding: 1rem 0 1rem 1.5rem;
      text-align: left;
      background-color: colors.$grey-85;
      border: none;

      &:first-child {
        border-radius: 0.5rem 0 0 0;
      }
      &:last-child {
        border-radius: 0 0.5rem 0 0;
      }
    }
  }
  tbody {
    tr {
      background-color: colors.$grey-95;
      &:nth-child(even) {
        background-color: colors.$grey-90;
      }

      td {
        padding: 1rem;
        font-weight: 800;
        &:first-child {
          text-transform: uppercase;
        }

        &:nth-child(4) {
          color: colors.$brand-green;
        }
        &:last-child {
          display: flex;
          gap: 10px;
        }
      }
    }
  }
}
