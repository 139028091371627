@use 'styles/colors';
@use 'styles/functions';
@import 'styles/typography';

$border-radius: functions.rem(8);

.root {
  display: flex;
  flex-direction: column;
  
  background-color: colors.$grey-85;
  border-radius: $border-radius;
  color: colors.$brand-pink;
  border-color: colors.$brand-pink;
  border-style: solid;
  border-width: functions.rem(2);
  color: colors.$white;
  margin-bottom: functions.rem(24);
  margin-top: functions.rem(24);
}

.header {
  @extend %title-5;
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  height: functions.rem(48);
  padding: 0 functions.rem(24);


  &::before,
  &::after {
    content: '';
    display: block;
  }

  &::before {
    border-radius: inherit;
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: inherit;
    z-index: 0;
  }
}

.body {
  background-color: colors.$grey-95;
  border-radius: $border-radius;
  flex: 1 1 auto;
  overflow: hidden;
}

.content {
  height: 100%;
  padding: functions.rem(24) functions.rem(24);
}

.md {
  .header {
    height: functions.rem(56);
    padding: 0 functions.rem(26);
  }
}
