// Breakpoints

// mobile <
$breakpoint-sm: 768;
// >= tablet-portrait <
$breakpoint-md: 960;
// >= tablet-landscape <
$breakpoint-lg: 1280;
// >= desktop

// Fonts

$base-font-size: 16;

$main-font: america, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
  Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', Arial, Helvetica,
  sans-serif;

$title-font: america, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
  Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', Arial, Helvetica,
  sans-serif;

$roboto-font: Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue',
  Arial, Helvetica, sans-serif;
